import { ApolloError, ServerError } from '@apollo/client'
import { Fragment, PropsWithChildren } from 'react'

import GenericError from '@/modules/shared/components/generic-error/GenericError'
import { Spinner } from '@/modules/shared/components/spinner/Spinner'
import { classNames } from '@/modules/shared/utils/classNames'

interface QueryResultProps extends PropsWithChildren {
  loading?: boolean
  error?: ApolloError | undefined
  size?: 'md'
  spinnerClassName?: string
  title?: string
}

export function QueryResult({ children, error, loading, size, spinnerClassName, title }: QueryResultProps) {
  const networkError = error?.networkError as ServerError

  if (networkError?.statusCode === 401) {
    return null
  }

  if (error) {
    return <GenericError />
  }

  if (loading) {
    return (
      <>
        <Spinner
          className={classNames({ 'mt-12 h-28 md:h-32': !size, 'my-5 h-14 md:w-16': size === 'md' }, spinnerClassName)}
        />
        {title && (
          <div className="mt-4 flex justify-center text-gray-500">
            <span>{title}</span>
          </div>
        )}
      </>
    )
  }

  return <Fragment>{children}</Fragment>
}
