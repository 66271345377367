import { MoreOptionsMenuProps } from '@/modules/shared/components/more-options/MoreOptionsMenu'
import {
  ENTERPRISE_CREDIT_NOTES_URL,
  ENTERPRISE_INVOICE_URL,
  ENTERPRISE_PRICED_CATALOGS_URL,
  ENTERPRISE_PURCHASER_RELATIONSHIP_URL,
  ENTERPRISE_PURCHASE_ORDERS_URL,
  ENTERPRISE_PURCHASING_CATALOGUES_URL,
  ENTERPRISE_PURCHASING_USERS_URL,
  ENTERPRISE_RECEIVING_NOTES_URL,
  ENTERPRISE_REQUISITION_URL,
  ENTERPRISE_SUPPLIER_RELATIONSHIP_URL,
  ENTERPRISE_SUPPLIER_URL,
  ENTERPRISE_USERS_URL,
} from '@/modules/shared/constants'
import { MoreOptionsItem } from '@/modules/shared/types/MoreOptionsItem'

export const openInEnterpriseURL = ({
  userId,
  accessUserId,
  invoiceId,
  supplierId,
  purchaserId,
  creditNoteId,
  components,
}: MoreOptionsMenuProps): string | undefined => {
  let url = import.meta.env.VITE_APP_NINJA_API_HOST

  if (userId) {
    url = `${ENTERPRISE_PURCHASING_USERS_URL}/${userId}`
  } else if (accessUserId) {
    url = `${ENTERPRISE_USERS_URL}/${accessUserId}`
  } else if (invoiceId) {
    url = `${ENTERPRISE_INVOICE_URL}/${invoiceId}`
  } else if (creditNoteId) {
    url = `${ENTERPRISE_CREDIT_NOTES_URL}/${creditNoteId}`
  } else if (components?.includes(MoreOptionsItem.Supplier)) {
    url = ENTERPRISE_SUPPLIER_URL
  } else if (supplierId) {
    url = `${ENTERPRISE_SUPPLIER_RELATIONSHIP_URL}/${supplierId}`
  } else if (components?.includes(MoreOptionsItem.AllBuyLists)) {
    url = ENTERPRISE_PURCHASING_CATALOGUES_URL
  } else if (components?.includes(MoreOptionsItem.AllRequisitions)) {
    url = ENTERPRISE_REQUISITION_URL
  } else if (components?.includes(MoreOptionsItem.AllInvoices)) {
    url = ENTERPRISE_INVOICE_URL
  } else if (components?.includes(MoreOptionsItem.AllSelfManagedCatalogs)) {
    url = ENTERPRISE_PRICED_CATALOGS_URL
  } else if (purchaserId) {
    url = ENTERPRISE_PURCHASER_RELATIONSHIP_URL
  } else if (components?.includes(MoreOptionsItem.AllCreditNotes)) {
    url = ENTERPRISE_CREDIT_NOTES_URL
  } else if (components?.includes(MoreOptionsItem.AllReceivingNotes)) {
    url = ENTERPRISE_RECEIVING_NOTES_URL
  } else if (components?.includes(MoreOptionsItem.AllPurchaseOrders)) {
    url = ENTERPRISE_PURCHASE_ORDERS_URL
  }
  return url
}
