import { WatchQueryFetchPolicy, useLazyQuery, useQuery } from '@apollo/client'
import { useEffect } from 'react'

import { GetUserPreferenceDocument } from '@/graphql/access/generated/getUserPreference.generated'
import { GetAllInvoicesDocument } from '@/graphql/purchasing/generated/getAllInvoices.generated'
import { Invoice, RansackDirection, RansackMatcher } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks/useSession'
import { getAllInvoicesSortOptions } from '@/modules/invoices/pages/all-invoices/table-view/getAllInvoicesSortOptions'
import { getAllInvoicesAwaitingMyApprovalSortOptions } from '@/modules/invoices/pages/invoices-awaiting-my-approval/table-view/getAllInvoicesAwaitingMyApprovalSortOptions'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { QueryValues, useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { generateRansackSort } from '@/modules/shared/utils/generateRansackSort'
import { onFetchMore, onNextPage, onPreviousPage } from '@/modules/shared/utils/paginationUtils'

interface UseGetAllInvoicesProps {
  awaitingMyApproval?: boolean
  paginationResultsPerPage?: number
  lazyQuery?: boolean
  supplierId?: number
  tableName?: string
  fetchPolicy?: WatchQueryFetchPolicy
  nextFetchPolicy?: WatchQueryFetchPolicy
}

const generateInvoicesFilter = (
  { searchValue, status, fromDate, toDate, supplierIds, deparmentIds, accountIds, ownerIds, supplierId }: QueryValues,
  awaitingMyApproval?: boolean
): RansackMatcher[] => {
  const filter: RansackMatcher[] = []

  if (searchValue) {
    filter.push({
      property: 'search_text_fuzzy',
      value: searchValue.trim(),
    })
  }
  if (status && !awaitingMyApproval) {
    if (status === 'export_ready' || status === 'exporting') {
      status = 'processing'
    } else if (status === 'exported') {
      status = 'processed'
    }
    filter.push({
      property: 'state_eq',
      value: status === 'all' ? '' : status,
    })
  }
  if (fromDate) {
    filter.push({
      property: 'invoiceDate_gt',
      value: fromDate,
    })
  }
  if (toDate) {
    filter.push({
      property: 'invoiceDate_lt',
      value: toDate,
    })
  }

  if (supplierIds) {
    filter.push({
      property: 'supplier_id_in',
      value: supplierIds,
    })
  }

  if (supplierId) {
    filter.push({
      property: 'supplier_id_in',
      value: [supplierId],
    })
  }

  if (deparmentIds && deparmentIds.length > 0) {
    filter.push({
      property: 'department_id_in',
      value: deparmentIds,
    })
  }

  if (accountIds && accountIds.length > 0) {
    filter.push({
      property: 'account_id_in',
      value: accountIds,
    })
  }

  if (ownerIds && ownerIds.length > 0) {
    filter.push({
      property: 'organisationUnit_id_in',
      value: ownerIds,
    })
  }

  if (awaitingMyApproval) {
    filter.push({
      property: 'state_eq',
      value: 'flagged',
    })
  }

  return filter
}

export function useGetAllInvoices({
  awaitingMyApproval,
  paginationResultsPerPage = 20,
  lazyQuery,
  supplierId,
  tableName,
  fetchPolicy = 'cache-and-network',
  nextFetchPolicy,
}: UseGetAllInvoicesProps) {
  const { currentUser } = useSession()
  const { searchValue, status, fromDate, toDate, supplierIds, deparmentIds, accountIds, ownerIds } =
    useWatchQueryParams()

  const storageKey = `columns:${tableName}:${currentUser?.id}`

  const { data: tableData, loading: tablePreferencesLoading } = useQuery(GetUserPreferenceDocument, {
    variables: { key: storageKey },
    skip: !tableName,
  })

  const allInvoicesAwaitingMyApprovalDefaultSort = getAllInvoicesAwaitingMyApprovalSortOptions().find(
    (sortOption) => sortOption.isDefault
  )
  const allInvoicesDefaultSort = getAllInvoicesSortOptions().find((sortOption) => sortOption.isDefault)

  const [fetchAllInvoices, { data, networkStatus, error, refetch, fetchMore }] = useLazyQuery(GetAllInvoicesDocument, {
    variables: {
      after: null,
      filter: {
        q: generateInvoicesFilter(
          {
            searchValue,
            status,
            fromDate,
            toDate,
            supplierIds,
            deparmentIds,
            accountIds,
            ownerIds,
            supplierId: supplierId !== undefined ? String(supplierId) : undefined,
          },
          awaitingMyApproval
        ),
      },
      first: paginationResultsPerPage,
      sort: awaitingMyApproval
        ? [
            {
              property: allInvoicesAwaitingMyApprovalDefaultSort?.key || '',
              direction: allInvoicesAwaitingMyApprovalDefaultSort?.direction || RansackDirection.Asc,
            },
          ]
        : generateRansackSort(tableData?.preference?.value, allInvoicesDefaultSort),
      awaitingApprovalForUserId: awaitingMyApproval ? currentUser?.id : null,
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    fetchPolicy,
    nextFetchPolicy,
    notifyOnNetworkStatusChange: true,
  })

  const pageInfo = data?.currentPurchaser?.invoices?.pageInfo

  useEffect(() => {
    if (!lazyQuery && !tablePreferencesLoading) {
      fetchAllInvoices()
    }
  }, [tablePreferencesLoading])

  return {
    invoices: extractEdges<Invoice>(data?.currentPurchaser?.invoices),
    loadingStates: checkNetworkStatus(networkStatus),
    paginationResultsPerPage,
    tablePreferencesLoading,
    error,
    networkStatus,
    refetch,
    onFetchMoreInvoices: () =>
      onFetchMore({
        endCursor: pageInfo?.endCursor || null,
        fetchMore,
        paginationResultsPerPage,
      }),
    onNextPage: () =>
      onNextPage({
        endCursor: pageInfo?.endCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onPreviousPage: () =>
      onPreviousPage({
        startCursor: pageInfo?.startCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    pageInfo,
    fetchAllInvoices,
  }
}
