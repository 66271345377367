import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import PaperlessDashboard from '@/modules/purchasing/pages/dashboard/PaperlessDashboard'
import AppLayout from '@/modules/shared/layouts/app-layout/AppLayout'
import NotFound from '@/modules/shared/pages/not-found/NotFound'

const AIScanningInboxRoutes = lazy(() => import('@/routes/paperless/routes/AIInboxRoutes'))
const InvoicesRoutes = lazy(() => import('@/routes/paperless/routes/InvoiceRoutes'))
const ExportCenterRoutes = lazy(() => import('@/routes/paperless/routes/ExportCenterRoutes'))
const MySupplierRoutes = lazy(() => import('@/routes/paperless/routes/MySupplierRoutes'))
const SupplierDirectoryRoutes = lazy(() => import('@/routes/paperless/routes/SupplierRoutes'))
const UserRoutes = lazy(() => import('@/routes/paperless/routes/UserRoutes'))
const MyAccountRoutes = lazy(() => import('@/routes/paperless/routes/MyAccountRoutes'))
const ReportRoutes = lazy(() => import('@/routes/paperless/routes/ReportRoutes'))
const SystemSettingsRoutes = lazy(() => import('@/routes/paperless/routes/SystemSettingsRoutes'))
const CreditNoteRoutes = lazy(() => import('@/routes/shared-routes/CreditNoteRoutes'))

export default function PaperlessRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route element={<AppLayout />}>
        <Route path="/dashboard" element={<PaperlessDashboard />} />
        <Route path="/ai-inbox/*" element={<AIScanningInboxRoutes />} />
        <Route path="/invoices/*" element={<InvoicesRoutes />} />
        <Route path="/export-center/*" element={<ExportCenterRoutes />} />
        <Route path="/my-suppliers/*" element={<MySupplierRoutes />} />
        <Route path="/supplier-directory/*" element={<SupplierDirectoryRoutes />} />
        <Route path="/users/*" element={<UserRoutes />} />
        <Route path="/my-account/*" element={<MyAccountRoutes />} />
        <Route path="/reports/*" element={<ReportRoutes />} />
        <Route path="/settings/*" element={<SystemSettingsRoutes />} />
        <Route path="/credit-notes/*" element={<CreditNoteRoutes />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
