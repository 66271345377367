import { PropsWithChildren } from 'react'
import { Link, generatePath } from 'react-router-dom'

interface PPNewDetailLinkProps extends PropsWithChildren {
  id?: string
  secondaryId?: string // for use on links that require two ids
  type?: 'invoice' | 'credit-note' | 'supplier' | 'supplier-directory' | 'supplier-directory-products' | 'user'
  testId?: string
  openNewTab?: boolean
  className?: string
  advanced?: boolean
}

export function PPNewDetailLink({
  id,
  secondaryId,
  type,
  openNewTab = false,
  children,
  className,
  testId,
}: PPNewDetailLinkProps) {
  const openNewTabProps = openNewTab ? { target: '_blank', rel: 'noreferrer' } : {}
  let linkTo = ''
  let defaultTestId = ''
  const defaultClassName = 'text-primary'

  switch (type) {
    case 'invoice':
      linkTo = '/invoices/:id/summary'
      defaultTestId = 'view-invoice-link'
      break
    case 'credit-note':
      linkTo = '/credit-notes/:id/summary'
      defaultTestId = 'view-credit-note-link'
      break
    case 'supplier':
      linkTo = '/my-suppliers/:id/supplier-details'
      defaultTestId = 'view-supplier-link'
      break
    case 'supplier-directory':
      linkTo = '/supplier-directory/:id/supplier-details'
      defaultTestId = 'view-supplier-directory-link'
      break
    case 'supplier-directory-products':
      linkTo = '/supplier-directory/:id/products'
      defaultTestId = 'view-supplier-directory-products-link'
      break
    case 'user':
      linkTo = '/users/:id/general'
      defaultTestId = 'view-user-link'
      break
    default:
      return <>{children}</>
  }

  return (
    <Link
      data-testid={testId || defaultTestId}
      to={generatePath(linkTo, { id, secondaryId })}
      className={className || defaultClassName}
      {...openNewTabProps}
    >
      {children}
    </Link>
  )
}
