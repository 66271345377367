import { useTranslation } from 'react-i18next'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { MoreOptionsMenu } from '@/modules/shared/components/more-options/MoreOptionsMenu'
import { PPNewDetailLink } from '@/modules/shared/components/ppnew-detail-link/PPNewDetailLink'
import { ThemeWrapper } from '@/modules/shared/components/theme-wrapper/ThemeWrapper'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { useMoney } from '@/modules/shared/hooks/useMoney'
import { InfoIcon } from '@/modules/shared/icons/InfoIcon'
import { InvoiceIcon } from '@/modules/shared/icons/InvoiceIcon'
import { MoreOptionsItem } from '@/modules/shared/types/MoreOptionsItem'
import { Themes } from '@/modules/shared/types/Themes'

interface DashboardRecentInvoicesRowProps {
  invoice: Invoice
  showActions?: boolean
}

export default function DashboardRecentInvoicesRow({ invoice, showActions }: DashboardRecentInvoicesRowProps) {
  const { totalValue, invoiceNumber, reference, id, currency } = invoice || {}
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <div className="flex w-full items-center justify-between gap-x-2">
      <div className="flex w-3/4 lg:w-1/2">
        <ThemeWrapper theme={Themes.Primary} className="flex size-14 shrink-0 items-center justify-center rounded-md">
          <InvoiceIcon className="size-10" />
        </ThemeWrapper>
        <div className="ml-4 flex flex-col justify-center">
          <p className="h-5 text-sm font-semibold sm:text-base">
            {formatMoney({ amount: totalValue, customCurrency: currency })}
          </p>
          <p className="text-xs text-gray-500 sm:text-sm">{`${invoiceNumber} ${reference || ''}`}</p>
        </div>
      </div>
      {showActions && (
        <div className="flex w-1/2 items-center justify-end gap-x-2 text-sm">
          <Tooltip content={t('general.viewInvoice', 'View Invoice')} delay={100}>
            <PPNewDetailLink
              type="invoice"
              id={String(id)}
              className="flex size-11 items-center justify-center rounded-full bg-gray-200 text-gray-600 transition hover:brightness-95"
              testId="invoice-open-button"
            >
              <InfoIcon className="size-8" />
            </PPNewDetailLink>
          </Tooltip>
          <MoreOptionsMenu
            buttonTheme="dark"
            iconClass="size-8"
            components={[
              MoreOptionsItem.OpenInPPlus,
              MoreOptionsItem.Help,
              MoreOptionsItem.Print,
              MoreOptionsItem.InvoiceLink,
            ]}
            invoiceId={id}
          />
        </div>
      )}
    </div>
  )
}
