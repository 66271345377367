import { useTranslation } from 'react-i18next'

import { GetSpendDataQuery } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import CurrencyCountUp from '@/modules/shared/components/charts/CurrencyCountUp'
import WidgetContainer from '@/modules/shared/components/charts/WidgetContainer'
import HorizontalBarChart from '@/modules/shared/components/charts/horizontal-bar-chart/HorizontalBarChart'

interface DashboardInvoiceChartProps {
  spendDataCurrent: GetSpendDataQuery['spendDataCurrentMonth']
  spendDataPrevious: GetSpendDataQuery['spendDataPreviousMonth']
}

export default function DashboardInvoiceChart({ spendDataCurrent, spendDataPrevious }: DashboardInvoiceChartProps) {
  const { t } = useTranslation()

  return (
    <WidgetContainer
      title={t('general.invoicesCount', {
        defaultValue_one: '{{ count }} Invoice',
        defaultValue_many: '{{ count }} Invoices',
        defaultValue_other: '{{ count }} Invoices',
        count: Number(spendDataCurrent?.invoicesProcessed),
      })}
      description={t('general.exportedThisMonth', 'Exported this Month')}
      rightTitle={<CurrencyCountUp value={Number(spendDataCurrent?.invoicesTotalValue)} />}
      rightDescription={t('general.totalInvoiceValue', 'Total Invoice Value')}
      testId="invoice-chart"
    >
      <HorizontalBarChart
        chartData={[
          {
            label: t('general.thisMonth', 'This Month'),
            value: Number(spendDataCurrent?.invoicesTotalValue),
            bg: 'bg-blue',
          },
          {
            label: t('general.lastMonth', 'Last Month'),
            value: Number(spendDataPrevious?.invoicesTotalValue),
            bg: 'bg-purple',
          },
        ]}
      />
    </WidgetContainer>
  )
}
