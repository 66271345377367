import { useTranslation } from 'react-i18next'

import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import { TruckIcon } from '@/modules/shared/icons/TruckIcon'
import SideNavLink from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/types/SideBarItem'
import { Themes } from '@/modules/shared/types/Themes'

interface SuppliersSideBarItemProps extends SideBarItem {
  supplierRoutesMatch: boolean
}

export const SuppliersSideBarItem = ({
  supplierRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: SuppliersSideBarItemProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={<TruckIcon className="size-7" />}
      title={t('general.suppliers', 'Suppliers')}
      activeColor={supplierRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 5}
      handleTriggerClick={() => changeOpenAccordionKey(5)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="suppliers-navigation"
        sideNavLinkItems={[
          {
            key: t('general.newSupplier', 'New Supplier '),
            target: '/my-suppliers/create',
            icon: <PlusIcon className="size-6 text-gray-500" />,
          },
          {
            key: t('general.mySuppliers', 'My Suppliers'),
            target: '/my-suppliers',
          },
          {
            key: t('general.supplierDirectory', 'Supplier Directory'),
            target: '/supplier-directory',
          },
        ]}
      />
    </Accordion>
  )
}
