import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import DashboardWelcome from '@/modules/purchasing/pages/dashboard/DashboardWelcome'
import FlaggedInvoicesCard from '@/modules/purchasing/pages/dashboard/FlaggedInvoicesCard'
import InvoicesAwaitingMyApprovalCard from '@/modules/purchasing/pages/dashboard/InvoicesAwaitingMyApprovalCard'
import DashboardRecentInvoices from '@/modules/purchasing/pages/dashboard/recent-invoices/DashboardRecentInvoices'
import DashboardCharts from '@/modules/purchasing/pages/dashboard/widgets/DashboardWidgets'
import { Breadcrumb } from '@/modules/shared/components/breadcrumb/Breadcrumb'
import { Container } from '@/modules/shared/components/container/Container'
import { useGetDashboardStats } from '@/modules/shared/hooks/useGetDashboardStats'
import { HomeIcon } from '@/modules/shared/icons/HomeIcon'

export default function PaperlessDashboard() {
  const { t } = useTranslation()
  const { dashboardStats } = useGetDashboardStats()
  return (
    <>
      <Helmet>
        <title>{t('general.paperlessDashboard', 'Paperless Dashboard')}</title>
      </Helmet>
      <Container layout="md" data-testid="dashboard">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.dashboard', 'Dashboard')}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <DashboardWelcome
            bannerDescription={t('dashboard.createRequisition.startInvoices', 'Start Approving Invoices')}
            action={{
              label: t('general.viewInvoices', 'View Invoices'),
              path: '/invoices/awaiting-my-approval',
            }}
            testId="dashboard-view-awaiting-invoices"
          />

          <div className="mt-1 flex flex-row flex-wrap justify-start sm:flex-nowrap sm:space-x-2">
            <InvoicesAwaitingMyApprovalCard count={dashboardStats?.invoicesAwaitingApprovalCount ?? 0} />
            <FlaggedInvoicesCard count={dashboardStats?.invoicesFlaggedCount ?? 0} />
          </div>
        </div>
        <DashboardRecentInvoices />
        <DashboardCharts />
      </Container>
    </>
  )
}
