import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import DashboardRecentInvoicesRow from '@/modules/purchasing/pages/dashboard/recent-invoices/table-view/DashboardRecentInvoicesRow'
import { useWindowSize } from '@/modules/shared/hooks/useWindowSize'

interface DashboardRecentInvoicesTableProps {
  invoices: Invoice[]
}

function DashboardRecentInvoicesTable({ invoices }: DashboardRecentInvoicesTableProps) {
  const { t } = useTranslation()
  const { sm } = useWindowSize()

  const navigate = useNavigate()

  if (!invoices.length) {
    return (
      <div className="mt-5 rounded-md border py-8 text-center text-sm" data-testid="dashboard-no-invoices">
        {t('invoices.allInvoices.noInvoices', "We couldn't find any Invoices to display.")}
      </div>
    )
  }

  return (
    <div className="rounded-md shadow-sm" data-testid="dashboard-recent-invoices-table">
      {invoices.map((invoice) => (
        <div
          key={invoice?.id}
          className="items-center border-b bg-white p-4 shadow-sm first:rounded-t-md last:rounded-b-md last:border-b-0"
        >
          <div
            className="cursor-pointer text-base"
            onClick={() => {
              navigate(`/invoices/${invoice.id}`)
            }}
          >
            <div className="flex items-center">
              <DashboardRecentInvoicesRow invoice={invoice} showActions={sm} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const MemoizedDashboardRecentInvoicesTable = memo(DashboardRecentInvoicesTable)
export default MemoizedDashboardRecentInvoicesTable
