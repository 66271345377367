import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ThemeWrapper } from '@/modules/shared/components/theme-wrapper/ThemeWrapper'
import { InvoiceIcon } from '@/modules/shared/icons/InvoiceIcon'
import { Themes } from '@/modules/shared/types/Themes'

interface InvoicesAwaitingMyApprovalCardProps {
  count: number
}

export default function InvoicesAwaitingMyApprovalCard({ count }: InvoicesAwaitingMyApprovalCardProps) {
  const { t } = useTranslation()

  return (
    <Link
      className="mt-2 flex h-min max-h-[5.5rem] w-full min-w-56 cursor-pointer items-center justify-between rounded-md bg-white p-4 shadow sm:w-1/2"
      to="/invoices/awaiting-my-approval"
      data-testid="dashboard-invoices-awaiting-my-approval"
    >
      <div className="flex items-center">
        <ThemeWrapper theme={Themes.Warning} className="flex size-14 items-center justify-center rounded-md">
          <InvoiceIcon className="size-10" />
        </ThemeWrapper>
        <span className="ml-3">
          <p className="font-bold">
            {t('dashboard.numberOfInvoices', '{{ number }} Invoices', {
              number: count,
            })}
          </p>
          <p className="text-sm text-gray-500">{t('general.awaitingMyApproval', 'Awaiting My Approval')}</p>
        </span>
      </div>
    </Link>
  )
}
