import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import CurrencyCountUp from '@/modules/shared/components/charts/CurrencyCountUp'
import { useWindowSize } from '@/modules/shared/hooks/useWindowSize'
import { HorizontalBarChartData } from '@/modules/shared/types/HorizontalBarChartData'
import { classNames } from '@/modules/shared/utils/classNames'

interface HorizontalBarProps {
  data: HorizontalBarChartData
  width: number
}

export default function HorizontalBar({ data, width }: HorizontalBarProps) {
  const { label, value, bg } = data
  const { xl } = useWindowSize()
  const { ref, inView } = useInView()
  const [showBar, setShowBar] = useState(false)

  useEffect(() => {
    if (inView) {
      setShowBar(true)
    }
  }, [inView])

  return (
    <div className="flex flex-col gap-x-4 gap-y-2 xl:flex-row xl:items-center" ref={ref}>
      <p className="w-full shrink-0 text-left xl:w-20">
        {label} {!xl && `- `}
        {!xl && <CurrencyCountUp value={value} />}
      </p>
      <div className="flex h-7 w-full rounded-full bg-gray-200">
        <div
          className={classNames(bg, `flex items-center justify-end rounded-full px-4 transition-all duration-1000`)}
          style={{ width: `${showBar ? width : 10}%` }}
        >
          {xl && (
            <p className="font-bold text-white">
              <CurrencyCountUp value={value} />
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
