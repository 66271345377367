import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useSession } from '@/modules/access/hooks/useSession'
import { useGetUserOrganisations } from '@/modules/access/pages/user/hooks/useGetUserOrganisations'
import { QueryResult } from '@/modules/shared/components/query-result/QueryResult'
import { Spinner } from '@/modules/shared/components/spinner/Spinner'
import SelectOrganisationUserListItem from '@/modules/shared/layouts/header/switch-organisation-modal/SelectOrganisationUserListItem'

interface SelectOrganisationUserListProps {
  onSelectOrganisation: Dispatch<SetStateAction<number | null>>
  selectedOrganisation: number | null
  showModal?: boolean
}

export default function SelectOrganisationUserList({
  onSelectOrganisation,
  selectedOrganisation,
  showModal,
}: SelectOrganisationUserListProps) {
  const { t } = useTranslation()
  const { currentUser } = useSession()

  const { fetchUserOrganisations, organisations, loadingStates, error, pageInfo, onFetchMoreOrganisations } =
    useGetUserOrganisations({ userId: Number(currentUser?.id), lazyQuery: true })

  useEffect(() => {
    if (showModal && !currentUser?.isAdmin) {
      fetchUserOrganisations()
    }
  }, [showModal])

  return (
    <QueryResult loading={loadingStates.loading} error={error}>
      {organisations.length ? (
        <InfiniteScroll
          dataLength={organisations.length}
          next={onFetchMoreOrganisations}
          hasMore={!!pageInfo?.hasNextPage}
          loader={<Spinner className="mt-5 h-14 md:w-16" />}
          scrollableTarget="InfiniteScroll"
        >
          <div className="border-b">
            {organisations.map((organisation) => (
              <SelectOrganisationUserListItem
                organisation={organisation}
                key={organisation.id}
                onSelectOrganisation={onSelectOrganisation}
                level={0}
                selectedOrganisation={selectedOrganisation}
                childrenCount={organisation.children?.totalCount || 0}
              />
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <p className="mt-10 text-center text-gray-500">
          {t('access.switchOrganisation.noOrganisations', 'There are no Organisations found.')}
        </p>
      )}
    </QueryResult>
  )
}
