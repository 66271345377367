import { useTranslation } from 'react-i18next'

import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { BadgeIcon } from '@/modules/shared/icons-special/BadgeIcon'
import { InvoiceIcon } from '@/modules/shared/icons/InvoiceIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import SideNavLink from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/types/SideBarItem'
import { Themes } from '@/modules/shared/types/Themes'

interface InvoicesSideBarItemProps extends SideBarItem {
  invoiceRoutesMatch: boolean
}
// TODO: move to the parent directory
export const InvoicesSideBarItem = ({
  invoiceRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: InvoicesSideBarItemProps) => {
  const { t } = useTranslation()

  return (
    <Accordion
      titlePrefixIcon={
        <BadgeIcon isBadged={false}>
          <InvoiceIcon className="size-7" />
        </BadgeIcon>
      }
      title={t('general.invoices', 'Invoices')}
      activeColor={invoiceRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 1}
      handleTriggerClick={() => changeOpenAccordionKey(1)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="invoice-navigation"
        sideNavLinkItems={[
          {
            key: t('general.newInvoice', 'New Invoice'),
            target: '/invoices/create',
            icon: <PlusIcon className="size-6 text-gray-500" />,
          },
          {
            key: t('general.viewAll', 'View All'),
            target: '/invoices',
          },
          {
            key: t('general.awaitingMyApproval', 'Awaiting My Approval'),
            target: '/invoices/awaiting-my-approval',
          },
        ]}
      />
    </Accordion>
  )
}
