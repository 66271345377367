import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useGetSpendData from '@/modules/purchasing/hooks/useGetSpendData'
import DashboardInvoiceChart from '@/modules/purchasing/pages/dashboard/widgets/charts/DashboardInvoiceChart'
import { Button } from '@/modules/shared/components/button/Button'
import { ConfirmDialog } from '@/modules/shared/components/confirm-dialog/ConfirmDialog'
import { QueryResult } from '@/modules/shared/components/query-result/QueryResult'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { ChartIcon } from '@/modules/shared/icons/ChartIcon'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'
import { Themes } from '@/modules/shared/types'

export default function DashboardCharts() {
  const { t } = useTranslation()
  const [showComingSoon, setShowComingSoon] = useState(false)

  const { spendDataCurrentMonth, spendDataPreviousMonth, loading, error } = useGetSpendData()

  return (
    <div className="flex flex-col gap-y-5" data-testid="dashboard-charts">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ChartIcon className="size-9" />
          <p className="ml-3 flex items-center text-sm">
            {t('general.dashboardWidgets', 'Dashboard Widgets')}
            <Tooltip content={t('spendData.headerTooltip', 'View key data insights for your Organisation.')}>
              <span className="ml-1">
                <QuestionMarkIcon className="inline w-5" />
              </span>
            </Tooltip>
          </p>
        </div>
        <Button
          className="hidden rounded-md bg-gray-200 px-5 py-3 text-center text-sm transition duration-200 hover:bg-gray-300 sm:block"
          onClick={() => setShowComingSoon(true)}
          data-testid="view-all-widgets-button"
        >
          <span>{t('general.viewAllWidgets', 'View All Widgets')}</span>
        </Button>
      </div>
      <QueryResult loading={loading} error={error}>
        <div className="flex flex-col gap-y-3">
          <DashboardInvoiceChart
            spendDataCurrent={spendDataCurrentMonth || null}
            spendDataPrevious={spendDataPreviousMonth || null}
          />
        </div>
      </QueryResult>
      <ConfirmDialog
        theme={Themes.Primary}
        isOpen={showComingSoon}
        setIsOpen={setShowComingSoon}
        title={t('general.comingSoon', 'Coming Soon!')}
        description={t(
          'spendData.comingSoonContent',
          "Soon you will be able to choose from a variety of widgets and select which widgets should appear for you here. We're still working on this feature."
        )}
        primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
        onPrimaryBtnClick={() => setShowComingSoon(false)}
        testId="widgets-coming-soon"
      />
    </div>
  )
}
