import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { useBackgroundReportPolling } from '@/modules/shared/hooks/useBackgroundReportPolling'
import PaperlessRoutes from '@/routes/paperless/PaperlessRoutes'

export default function AppRoutes() {
  const { loading, currentOrganisation } = useSessionUser()

  // Background tasks that need to run without unmounting
  useBackgroundReportPolling()

  if (loading) return null

  if (!loading && currentOrganisation) {
    return <PaperlessRoutes />
  }
}
