import { ComponentProps, FC, PropsWithChildren } from 'react'
import { Link, To } from 'react-router-dom'

import { ChevronRightIcon } from '@/modules/shared/icons/ChevronRightIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface BreadcrumbItemProps extends PropsWithChildren<Omit<ComponentProps<'li'>, 'className'>> {
  href?: To
  icon?: FC<ComponentProps<'svg'>>
  separator?: FC<ComponentProps<'svg'>>
}

function BreadcrumbItem(props: BreadcrumbItemProps) {
  const { href, children, icon: Icon, separator: Separator, ...restProps } = props
  const isLink = Boolean(href)

  const Component = isLink ? Link : 'span'
  const SeparatorComponent = Separator || ChevronRightIcon

  return (
    <li className="group flex items-center whitespace-nowrap" {...restProps}>
      <SeparatorComponent
        className="mx-1 size-5 text-gray-400 group-first:hidden md:mx-2"
        aria-hidden="true"
        aria-label="breadcrumb-separator"
      />
      <Component
        className={classNames({
          'flex items-center text-sm font-medium text-gray-500': isLink,
          'flex items-center text-sm font-medium text-gray-700': !isLink,
        })}
        to={href || '/'}
      >
        {Icon && (
          <Icon
            className="mr-2 size-7 text-gray-400 group-first:mr-0"
            aria-hidden="true"
            aria-label="breadcrumb-icon"
          />
        )}
        {children}
      </Component>
    </li>
  )
}

export default BreadcrumbItem
