import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FlaggingMessageFragmentDoc } from './FlaggingMessage.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetAllInvoicesDocument = gql`
  query GetAllInvoices(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: RansackFilterType
    $sort: [RansackSortType!]
    $awaitingApprovalForUserId: Int
  ) {
    currentPurchaser {
      id
      invoices(
        after: $after
        before: $before
        last: $last
        first: $first
        filter: $filter
        sort: $sort
        awaitingApprovalForUserId: $awaitingApprovalForUserId
      ) {
        edges {
          node {
            id
            invoiceNumber
            status
            reference
            invoiceDate
            attachmentUrl
            isOperationallyApproved
            isFinanciallyApproved
            financiallyApprovable
            operationallyApprovable
            createdAt
            creator {
              id
              fullName
            }
            currency
            financialApprovers {
              edges {
                node {
                  id
                  createdAt
                  user {
                    id
                    fullName
                  }
                }
              }
            }
            operationalApprovers {
              edges {
                node {
                  id
                  createdAt
                  user {
                    id
                    fullName
                  }
                }
              }
            }
            purchaser {
              id
              name
            }
            organisationUnit {
              id
              name
            }
            supplier {
              id
              name
            }
            department {
              id
              name
            }
            account {
              id
              accountName
              code
            }
            totalTaxValue
            totalValue
            totalValueExTax
            flaggingMessages {
              ...FlaggingMessage
            }
            hasSuperFlag
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${FlaggingMessageFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllInvoicesQuery, Types.GetAllInvoicesQueryVariables>
