import { useTranslation } from 'react-i18next'

import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { BadgeIcon } from '@/modules/shared/icons-special/BadgeIcon'
import AIIcon from '@/modules/shared/icons/AIIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import SideNavLink from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/types/SideBarItem'
import { Themes } from '@/modules/shared/types/Themes'

interface AIScanningInboxSideBarItemsProps extends SideBarItem {
  aiScanningRoutesMatch: boolean
}

export default function AIScanningInboxSideBarItems({
  aiScanningRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: AIScanningInboxSideBarItemsProps) {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={
        <BadgeIcon isBadged={false}>
          <AIIcon className="size-7" />
        </BadgeIcon>
      }
      title={t('general.aiScanningInbox', 'AI Scanning Inbox')}
      activeColor={aiScanningRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 0}
      handleTriggerClick={() => changeOpenAccordionKey(0)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="ai-scanning-inbox-navigation"
        sideNavLinkItems={[
          {
            key: t('general.newAIScanningTask', 'New AI Scanning Task'),
            target: '/ai-inbox/create',
            icon: <PlusIcon className="size-6 text-gray-500" />,
          },
          {
            key: t('general.viewAll', 'View All'),
            target: '/ai-inbox',
          },
        ]}
      />
    </Accordion>
  )
}
