import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useGetAllInvoices } from '@/modules/invoices/hooks/useGetAllInvoices'
import DashboardRecentInvoicesTable from '@/modules/purchasing/pages/dashboard/recent-invoices/table-view/DashboardRecentInvoicesTable'
import { QueryResult } from '@/modules/shared/components/query-result/QueryResult'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { InvoiceIcon } from '@/modules/shared/icons/InvoiceIcon'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'

export default function DashboardRecentInvoices() {
  const { t } = useTranslation()

  const { error, loadingStates, invoices } = useGetAllInvoices({ paginationResultsPerPage: 5 })

  return (
    <>
      <div className="flex flex-col justify-center space-y-5 pb-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <InvoiceIcon className="size-9" />
            <p className="ml-3 text-sm" data-testid="dashboard-recent-invoices">
              {t('general.recentInvoices', 'Recent Invoices')}
              <Tooltip
                content={t(
                  'dashboard.recentInvoices.tooltip',
                  'The most recently created invoices are displayed in this table.'
                )}
              >
                <span className="ml-1">
                  <QuestionMarkIcon className="inline w-5" />
                </span>
              </Tooltip>
            </p>
          </div>
          <Link
            to={'/invoices'}
            className="hidden rounded-md bg-gray-200 px-5 py-3 text-center text-sm transition duration-200 hover:bg-gray-300 sm:block"
          >
            <span>{t('general.viewAllInvoices', 'View All Invoices')}</span>
          </Link>
        </div>
        <QueryResult loading={loadingStates.loading} error={error} size={'md'}>
          <DashboardRecentInvoicesTable invoices={invoices} />
        </QueryResult>
      </div>
    </>
  )
}
