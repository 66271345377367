import { matchPath, useLocation } from 'react-router-dom'

export default function useShouldHideLayout() {
  const location = useLocation()

  const invoicesOutsideLayout = [
    '/invoices/create',
    '/invoices/:invoiceId/add-line',
    '/invoices/:invoiceId/power-editor',
    '/invoices/:invoiceId/power-editor/summary',
    '/invoices/:invoiceId/power-editor/flags',
    '/invoices/:invoiceId/power-editor/analysis',
    '/invoices/:invoiceId/power-editor/settings',
    '/invoices/:invoiceId/power-editor/comments',
    '/invoices/:invoiceId/power-editor/purchase-order',
    '/invoices/:invoiceId/power-editor/receiving-notes',
    '/invoices/:invoiceId/power-editor/audit-log',
    '/invoices/:invoiceId/power-editor/attachments',
    '/invoices/export-center/create',
    '/invoices/invoice-creator',
    '/ai-inbox/create',
  ]

  const aiScanningInboxOutsideLayout = ['/ai-inbox/create']
  const exportCenterOutsidelayout = ['/export-center/create']
  const mySuppliersOutsideLayout = ['/my-suppliers/create']

  const noLayoutPaths = [
    ...aiScanningInboxOutsideLayout,
    ...invoicesOutsideLayout,
    ...exportCenterOutsidelayout,
    ...mySuppliersOutsideLayout,
  ]

  const shouldHideLayout = noLayoutPaths.some((path) => matchPath(path, location.pathname))

  return {
    noLayoutPaths,
    shouldHideLayout,
  }
}
