import { matchRoutes, useLocation } from 'react-router-dom'

import {
  aiScanningInboxRoutes,
  exportCenterRoutes,
  invoiceRoutes,
  reportsRoutes,
  supplierRoutes,
  systemSettingRoutes,
  usersRoutes,
} from '@/modules/shared/constants'

export function useMatchAppRoutes() {
  const location = useLocation()

  const allRoutes = [
    aiScanningInboxRoutes,
    invoiceRoutes,
    exportCenterRoutes,
    systemSettingRoutes,
    supplierRoutes,
    usersRoutes,
    reportsRoutes,
  ]

  const matchedRouteIndex = allRoutes.findIndex((route) => matchRoutes(route, location))

  const routeMatches = allRoutes.map((_, index) => index === matchedRouteIndex)

  return {
    aiScanningInboxRoutesMatch: routeMatches[0],
    invoiceRoutesMatch: routeMatches[1],
    exportCenterRoutesMatch: routeMatches[2],
    systemSettingRoutesMatch: routeMatches[3],
    supplierRoutesMatch: routeMatches[4],
    usersRoutesMatch: routeMatches[5],
    reportsRoutesMatch: routeMatches[6],
    accordionDefaultKey: matchedRouteIndex !== -1 ? matchedRouteIndex : null,
  }
}
