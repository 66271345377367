import HorizontalBar from '@/modules/shared/components/charts/horizontal-bar-chart/HorizontalBar'
import { useWindowSize } from '@/modules/shared/hooks/useWindowSize'
import { HorizontalBarChartData } from '@/modules/shared/types/HorizontalBarChartData'

export interface HorizontalBarChartProps {
  chartData: HorizontalBarChartData[]
}

export default function HorizontalBarChart({ chartData }: HorizontalBarChartProps) {
  const maxWidth = Math.max(...chartData.map((data) => data.value))
  const { twoXL } = useWindowSize()

  return (
    <div className="flex flex-col gap-y-4 border bg-gray-100 px-4 py-6 text-sm">
      {chartData.map((data) => {
        const width = Math.max((data.value / maxWidth || 0) * 100, twoXL ? 8 : 10)
        return <HorizontalBar data={data} width={width} key={data.label} />
      })}
    </div>
  )
}
