import { ButtonTheme } from '@/modules/shared/types/ButtonTheme'

export const buttonThemeStyle = (buttonTheme?: ButtonTheme): string => {
  switch (buttonTheme) {
    case 'dark':
      return 'items-center justify-between rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:bg-gray-300'
    default:
      return 'items-center justify-between outline-none hover:opacity-75'
  }
}
