import { useQuery } from '@apollo/client'

import { GetSpendDataDocument } from '@/graphql/purchase-plus/generated/getSpendData.generated'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

export default function useGetSpendData() {
  const { data, loading, error } = useQuery(GetSpendDataDocument, {
    context: { uri: PURCHASE_PLUS_GRAPHQL_API },
  })

  return {
    spendDataCurrentMonth: data?.spendDataCurrentMonth,
    spendDataPreviousMonth: data?.spendDataPreviousMonth,
    loading,
    error,
  }
}
