import i18next from 'i18next'

import { RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import { SortOption } from '@/modules/shared/components/table/types'

export const getAllInvoicesSortOptions = (): SortOption[] => {
  return [
    {
      key: 'invoiceDate',
      name: i18next.t('table.sortOptions.invoiceDateNewToOld', 'Invoice Date - Newest to Oldest'),
      isDefault: true,
      direction: RansackDirection.Desc,
    },
    {
      key: 'invoiceDate',
      name: i18next.t('table.sortOptions.invoiceDateOldToNew', 'Invoice Date - Oldest to Newest'),
      direction: RansackDirection.Asc,
    },
    {
      key: 'invoiceNumber',
      name: i18next.t('table.sortOptions.invoiceNumberHighLow', 'Invoice Number - Highest to Lowest'),
      direction: RansackDirection.Desc,
    },
    {
      key: 'invoiceNumber',
      name: i18next.t('table.sortOptions.invoiceNumberLowHigh', 'Invoice Number - Lowest to Highest'),
      direction: RansackDirection.Asc,
    },
    {
      key: 'purchaser_name',
      name: i18next.t('table.sortOptions.purchaserAZ', 'Purchaser - A to Z'),
      direction: RansackDirection.Asc,
    },
    {
      key: 'purchaser_name',
      name: i18next.t('table.sortOptions.purchaseZA', 'Purchaser - Z to A'),
      direction: RansackDirection.Desc,
    },
    {
      key: 'organisationUnit_name',
      name: i18next.t('table.sortOptions.ownerAZ', 'Owner - A to Z'),
      direction: RansackDirection.Asc,
    },
    {
      key: 'organisationUnit_name',
      name: i18next.t('table.sortOptions.ownerZA', 'Owner - Z to A'),
      direction: RansackDirection.Desc,
    },
    {
      key: 'supplier_name',
      name: i18next.t('table.sortOptions.supplierAZ', 'Supplier - A to Z'),
      direction: RansackDirection.Asc,
    },
    {
      key: 'supplier_name',
      name: i18next.t('table.sortOptions.supplierZA', 'Supplier - Z to A'),
      direction: RansackDirection.Desc,
    },
    {
      key: 'department_name',
      name: i18next.t('table.sortOptions.departmentAZ', 'Department - A to Z'),
      direction: RansackDirection.Asc,
    },
    {
      key: 'department_name',
      name: i18next.t('table.sortOptions.departmentZA', 'Department - Z to A'),
      direction: RansackDirection.Desc,
    },
    {
      key: 'account_name',
      name: i18next.t('table.sortOptions.accountCodeAZ', 'Account Code - A to Z'),
      direction: RansackDirection.Asc,
    },
    {
      key: 'account_name',
      name: i18next.t('table.sortOptions.accountCodeZA', 'Account Code - Z to A'),
      direction: RansackDirection.Desc,
    },
    {
      key: 'totalValue',
      name: i18next.t('table.sortOptions.totalHighLow', 'Total - Highest to Lowest'),
      direction: RansackDirection.Desc,
    },
    {
      key: 'totalValue',
      name: i18next.t('table.sortOptions.totalLowHigh', 'Total - Lowest to Highest'),
      direction: RansackDirection.Asc,
    },
    {
      key: 'createdAt',
      name: i18next.t('table.sortOptions.createdOnNewToOld', 'Created On - Newest to Oldest'),
      direction: RansackDirection.Desc,
    },
    {
      key: 'createdAt',
      name: i18next.t('table.sortOptions.createdOnOldToNew', 'Created On - Oldest to Newest'),
      direction: RansackDirection.Asc,
    },
  ]
}
