import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import ApolloProviderWrapper from '@/apollo/ApolloProviderWrapper'
import i18nextConfig from '@/locales/i18nextConfig'
import AlertDialog from '@/modules/shared/components/alert-dialog/AlertDialog'
import { ScrollToTop } from '@/modules/shared/components/scroll-to-top/ScrollToTop'
import { ToastContainerItem } from '@/modules/shared/components/toast/ToastContainerItem'
import { AlertProvider } from '@/modules/shared/providers/AlertProvider'
import ReactAriaI18nProvider from '@/modules/shared/providers/ReactAriaI18nProvider'
import AppRoutes from '@/routes/AppRoutes'

i18nextConfig()

function App() {
  return (
    <AlertProvider>
      <ApolloProviderWrapper>
        <ReactAriaI18nProvider>
          <HelmetProvider>
            <BrowserRouter>
              <ScrollToTop />
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <AppRoutes />
                <ToastContainerItem />
              </QueryParamProvider>
            </BrowserRouter>
          </HelmetProvider>
          <AlertDialog />
        </ReactAriaI18nProvider>
      </ApolloProviderWrapper>
    </AlertProvider>
  )
}

export default App
