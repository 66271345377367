import { Dispatch, PropsWithChildren, ReactNode, SetStateAction } from 'react'

import Button from '@/modules/shared/components/button/Button'
import { classNames } from '@/modules/shared/utils/classNames'

type WidgetTab = {
  label: string
  value: string
}

export interface WidgetContainerProps extends PropsWithChildren {
  title: string
  description: string
  rightTitle?: string | ReactNode
  rightDescription?: string
  setActiveWidgetTab?: Dispatch<SetStateAction<string>>
  activeWidgetTab?: string
  testId?: string
  widgetTabs?: WidgetTab[]
  onWidgetTabChange?: (value: string) => void
}

export default function WidgetContainer({
  title,
  description,
  rightTitle,
  rightDescription,
  setActiveWidgetTab,
  activeWidgetTab,
  testId,
  children,
  widgetTabs = [],
  onWidgetTabChange,
}: WidgetContainerProps) {
  return (
    <div className="rounded-md border bg-white shadow-sm" data-testid={testId}>
      <div className="flex justify-between gap-x-2 border-b p-4">
        <div>
          <p className="font-bold">{title}</p>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
        <div>
          {widgetTabs.length > 0 && (
            <div data-testid="widget-tabs" className="hidden gap-1.5 rounded-lg bg-gray-200 px-1.5 py-1 sm:flex">
              {widgetTabs.map((widgetTab) => (
                <Button
                  key={widgetTab.value}
                  className={classNames(
                    'h-9 min-w-[7.5rem] rounded-md border px-5 text-sm text-gray-500 hover:bg-gray-300',
                    {
                      'pointer-events-none border-gray-300 bg-white ': widgetTab.value === activeWidgetTab,
                    }
                  )}
                  onClick={() => {
                    setActiveWidgetTab && setActiveWidgetTab(widgetTab.value)
                    onWidgetTabChange && onWidgetTabChange(widgetTab.value)
                  }}
                  data-testid={`${widgetTab.value.toLowerCase()}-tab`}
                >
                  {widgetTab.label}
                </Button>
              ))}
            </div>
          )}
          <p className="text-right font-bold">{rightTitle}</p>
          <p className="text-right text-sm text-gray-500">{rightDescription}</p>
        </div>
      </div>
      <div className="p-4">{children}</div>
    </div>
  )
}
