import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import NoahDrinks from '@/assets/astore/noah-drinks.png'
import MobileVisual from '@/assets/pplus/mobile-visual.svg'
import { useSession } from '@/modules/access/hooks/useSession'
import { ChevronRightIcon } from '@/modules/shared/icons/ChevronRightIcon'
import { ElementSwitch } from '@/modules/shared/layouts/ElementSwitch'

interface DashboardWelcomeProps {
  bannerDescription: string
  action: {
    label: string
    path: string
  }
  testId: string
}

export default function DashboardWelcome({ bannerDescription, action, testId }: DashboardWelcomeProps) {
  const { t } = useTranslation()

  const { currentUser, loading } = useSession()

  return (
    <>
      <div className="mt-5 flex flex-col justify-between rounded-md bg-white p-6 shadow sm:h-44 sm:flex-row">
        <div className="flex flex-col justify-between gap-y-3">
          <span className="flex flex-col gap-y-2 text-center sm:text-left">
            <p className="text-sm leading-none">
              {t('general.welcomeUser', 'Welcome {{user}}', { user: `${currentUser?.fullName}!` })}{' '}
            </p>
            <p className="font-semibold leading-none">{bannerDescription}</p>
          </span>
          {!loading && (
            <ElementSwitch
              aStoreElement={<img className="sm:hidden" src={NoahDrinks} alt="noah-drink-desktop" />}
              defaultElement={<img className="sm:hidden" src={MobileVisual} alt="mobile-visual-desktop" />}
            />
          )}
          <Link
            to={action.path}
            data-testid={testId}
            className="flex h-11 w-full items-center justify-between gap-x-3 rounded-md bg-primary pl-5 pr-3 text-sm text-white transition duration-200 hover:brightness-95 sm:w-fit"
          >
            <span className="">{action.label}</span>
            <ChevronRightIcon className="size-6" />
          </Link>
        </div>
        <div className="hidden items-center sm:flex">
          {!loading && (
            <ElementSwitch
              aStoreElement={<img className="h-40" src={NoahDrinks} alt="noah-drink-mobile" />}
              defaultElement={<img className="h-40" src={MobileVisual} alt="mobile-visual-mobile" />}
            />
          )}
        </div>
      </div>
    </>
  )
}
