import CountUp from 'react-countup'

import { useMoney } from '@/modules/shared/hooks/useMoney'

interface CurrencyCountUpProps {
  value: number
}

export default function CurrencyCountUp({ value }: CurrencyCountUpProps) {
  const { formatMoney } = useMoney()
  const formattedCurrency = formatMoney({ amount: value })
  const startIndex = formattedCurrency.indexOf(formattedCurrency.match(/\d/)?.[0] || '')
  const prefix = formattedCurrency.slice(0, startIndex)

  return <CountUp end={value} duration={1} enableScrollSpy scrollSpyOnce decimals={2} prefix={prefix} />
}
