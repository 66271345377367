import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { useMatchAppRoutes } from '@/modules/shared/hooks/useMatchAppRoutes'
import { HomeIcon } from '@/modules/shared/icons/HomeIcon'
import { SideBarContext } from '@/modules/shared/layouts/app-layout/SideBarContext'
import AIScanningInboxSideBarItems from '@/modules/shared/layouts/side-navigation/AIScanningInboxSideBarItems'
import ExportCenterSideBarItems from '@/modules/shared/layouts/side-navigation/ExportCenterSideBarItems'
import { AccountSideBarItems } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/AccountSideBarItems'
import { InvoicesSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/InvoicesSideBarItem'
import { ReportsSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/ReportsSideBarItem'
import { SuppliersSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/SuppliersSideBarItem'
import { UsersSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/UsersSideBarItem'
import { classNames } from '@/modules/shared/utils/classNames'

export default function SideNavigation() {
  const sideBar = useContext(SideBarContext)

  const { t } = useTranslation()

  const {
    invoiceRoutesMatch,
    aiScanningInboxRoutesMatch,
    accordionDefaultKey,
    exportCenterRoutesMatch,
    supplierRoutesMatch,
    usersRoutesMatch,
    reportsRoutesMatch,
  } = useMatchAppRoutes()

  const [openAccordionKey, setOpenAccordionKey] = useState<number | undefined>()

  useEffect(() => {
    if (accordionDefaultKey) {
      setOpenAccordionKey(accordionDefaultKey)
    }
  }, [accordionDefaultKey])

  const changeOpenAccordionKey = (key: number | undefined) => {
    if (key === openAccordionKey) {
      setOpenAccordionKey(undefined)
    } else {
      setOpenAccordionKey(key)
    }
  }

  const onCloseDrawer = () => sideBar?.setDrawerOpen && sideBar.setDrawerOpen(false)

  return (
    <>
      <nav className="flex-1 pb-1 text-sm" data-testid="side-navigation-items">
        <section className="space-y-1.5">
          <NavLink
            data-testid="home-navigation-link"
            to="/dashboard"
            onClick={() => onCloseDrawer()}
            className={({ isActive }) => {
              return classNames(
                'flex h-11 items-center gap-x-2 rounded-md px-3 transition duration-200 hover:brightness-95',
                {
                  'bg-primary text-white': isActive,
                  'bg-gray-200': !isActive,
                }
              )
            }}
          >
            <HomeIcon className="size-7" />
            {t('general.home', 'Home')}
          </NavLink>
          <AIScanningInboxSideBarItems
            aiScanningRoutesMatch={aiScanningInboxRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <InvoicesSideBarItem
            invoiceRoutesMatch={invoiceRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <ExportCenterSideBarItems
            exportCenterRoutesMatch={exportCenterRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <SuppliersSideBarItem
            supplierRoutesMatch={supplierRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <ReportsSideBarItem
            reportsRoutesMatch={reportsRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <UsersSideBarItem
            usersRoutesMatch={usersRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
        </section>
        <AccountSideBarItems
          openAccordionKey={openAccordionKey}
          changeOpenAccordionKey={changeOpenAccordionKey}
          onCloseDrawer={onCloseDrawer}
        />
      </nav>
    </>
  )
}
